import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#5F7367]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <h1 className="text-4xl sm:text-7xl font-bold text-[#DCD8BC]">
          Hello, I'm Rory,
        </h1>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#EAE7D7]">
          a Full Stack Developer.
        </h1>
        <p className="text-[#F5F4EC] py-4 max-w-[700px]">
          I'm a Midlands based full-stack developer on a journey to my first
          role in tech. I was fortunate enough to be chosen from over 3000
          applicants for a place on the School of Code Bootcamp in April 2022.
        </p>
        <div>
          <Link to="projects" smooth={true} duration={500}>
            <button className="text-[#DCD8BC] group border-2 border-[#DCD8BC] px-6 py-3 my-2 flex items-center hover:bg-[#804E49] hover:border-[#804E49]">
              My Projects
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
