import React from "react";
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import JavaScript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import Node from "../assets/node.png";
import AWS from "../assets/aws.png";
import Postgres from "../assets/postgres.png";
import Express from "../assets/express.png";
import TypeScript from "../assets/typescript.png";
import Heroku from "../assets/heroku.png";
import Jest from "../assets/jest.png";
import Cypress from "../assets/cypress.png";
import NPM from "../assets/npm.png";
import Postman from "../assets/postman.png";
import Docker from "../assets/docker.png";
import Next from "../assets/nextjs.png";

const Tech = () => {
  return (
    <div name="tech" className="w-full h-screen bg-[#5F7367] text-[#F5F4EC]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-4 flex flex-col justify-center w-full h-full">
        <div>
          <h1 className="text-4xl font-bold inline border-b-4 border-[#804E49] text-[#DCD8BC]">
            Tech Stack
          </h1>
          <p className="py-6 hidden sm:block">
            These are some of the technologies I've been working with on my
            recent projects. To see examples of my work using these, take a look
            at my portfolio.
          </p>
        </div>

        <div className="w-full grid grid-cols-4 sm:grid-cols-8 gap-4 text-center py-8">
          <div className="hover:scale-110 duration-500 hidden sm:block">
            <img className="w-20 mx-auto" src={HTML} alt="HTML icon" />
            <p className="my-4">HTML</p>
          </div>
          <div className="hover:scale-110 duration-500 hidden sm:block">
            <img className="w-20 mx-auto" src={CSS} alt="HTML icon" />
            <p className="my-4">CSS</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={JavaScript} alt="HTML icon" />
            <p className="my-4">JavaScript</p>
          </div>
          <div className=" hover:scale-110 duration-500 hidden sm:block">
            <img className="w-20 mx-auto" src={TypeScript} alt="HTML icon" />
            <p className="my-4">TypeScript</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={ReactImg} alt="HTML icon" />
            <p className="my-4">React</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Next} alt="HTML icon" />
            <p className="my-4">Next.js</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Node} alt="HTML icon" />
            <p className="my-4">Node.js</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Express} alt="HTML icon" />
            <p className="my-4">Express</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Postgres} alt="HTML icon" />
            <p className="my-4">PostgreSQL</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={AWS} alt="HTML icon" />
            <p className="my-4">aws</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Heroku} alt="HTML icon" />
            <p className="my-4">Heroku</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Jest} alt="HTML icon" />
            <p className="my-4">Jest</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Cypress} alt="HTML icon" />
            <p className="my-4">Cypress</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={NPM} alt="HTML icon" />
            <p className="my-4">npm</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Postman} alt="HTML icon" />
            <p className="my-4">Postman</p>
          </div>
          <div className=" hover:scale-110 duration-500 hidden sm:block">
            <img className="w-20 mx-auto" src={Docker} alt="HTML icon" />
            <p className="my-4">Docker</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
