import React from "react";
import Giraffe from "../assets/giraffe.png";
import Budget from "../assets/budget.png";
import Meme from "../assets/meme.png";
import Soon from "../assets/soon.png";
import Bok from "../assets/Bok.png";

const Projects = () => {
  return (
    <div
      name="projects"
      className="w-full md:h-screen bg-[#5F7367] text-[#F5F4EC]"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-4">
          <h1 className="text-4xl font-bold inline border-b-4 border-[#804E49] text-[#DCD8BC]">
            Projects
          </h1>
          <p className="py-6">
            Here are some of the projects I've been working on recently. A
            mixture of School of Code Bootcamp projects and personal projects.
            Details of each project can be found in the GitHub repos.
          </p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Bok})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-xl font-bold text-[#F5F4EC] tracking-wider">
                Final 4 Week Project
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://hackson5bok.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    Demo
                  </button>
                </a>
                <a
                  href="https://github.com/SchoolOfCode/final-project_back-end-hackson5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    GitHub
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Giraffe})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-xl font-bold text-[#F5F4EC] tracking-wider">
                React Helpdesk App
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://giraffe-support.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    Demo
                  </button>
                </a>
                <a
                  href="https://github.com/RoryHog/frontend-giraffe-support-ticketing-system"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    GitHub
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Budget})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-xl font-bold text-[#F5F4EC] tracking-wider">
                CRUD React Budget App
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://rorys-budget-app.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    Demo
                  </button>
                </a>
                <a
                  href="https://github.com/RoryHog/react_expenses_app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    GitHub
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Meme})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-xl font-bold text-[#F5F4EC] tracking-wider">
                React Meme Generator
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://rory-meme.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    Demo
                  </button>
                </a>
                <a
                  href="https://github.com/RoryHog/meme-generator-hackathon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                    GitHub
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div className="hidden sm:block">
            <div
              style={{ backgroundImage: `url(${Soon})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center text-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-xl font-bold text-[#F5F4EC] tracking-wider">
                  Project Coming Soon
                </span>
                <div className="pt-8 text-center">
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                      GitHub
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div className="hidden sm:block">
            <div
              style={{ backgroundImage: `url(${Soon})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center text-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-xl font-bold text-[#F5F4EC] tracking-wider">
                  Project Coming Soon
                </span>
                <div className="pt-8 text-center">
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#DCD8BC] text-[#804E49] font-bold text-lg">
                      GitHub
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
