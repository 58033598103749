import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-[#5F7367] flex justify-center items-center p-4"
    >
      <form
        method="POST"
        action="https://getform.io/f/0a897c9e-37eb-4a24-bf7f-f49e3602afd4"
        className="flex flex-col max-w-[600px] w-full"
      >
        <div className="pb-2">
          <h1 className="text-4xl font-bold inline border-b-4 border-[#804E49] text-[#DCD8BC]">
            Contact
          </h1>
          <p className="text-[#F5F4EC] py-6">
            {" "}
            I'd love to hear from you, use the form below to get in touch.
          </p>
        </div>
        <input
          className="bg-[#F5F4EC] p-2"
          type="text"
          placeholder="Name"
          name="name"
        />
        <input
          className="my-4 p-2 bg-[#F5F4EC]"
          type="email"
          placeholder="Email"
          name="email"
        />
        <textarea
          className="bg-[#F5F4EC] p-2"
          name="message"
          rows="6"
          placeholder="Message"
        ></textarea>
        <button className="text-[#DCD8BC] border-2 border-[#DCD8BC] hover:bg-[#804E49] hover:border-[#804E49] px-4 py-3 my-8 mx-auto flex items-center">
          Get in Touch
        </button>
        <div className="mx-auto flex items-center">
          <a
            className="flex justify-between px-5 items-center w-full text-[#DCD8BC] hover:text-[#F5F4EC] hover:scale-110 duration-500"
            href="https://linkedin.com/in/roryhog"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={40} />
          </a>
          <a
            className="flex justify-between px-5 items-center w-full text-[#DCD8BC] hover:text-[#F5F4EC] hover:scale-110 duration-500"
            href="https://github.com/RoryHog"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub size={40} />
          </a>
        </div>
      </form>
    </div>
  );
};

export default Contact;
