import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import Rory from "../assets/Rory.png";

const About = () => {
  return (
    <div name="about" className="w-full bg-[#5F7367] text-[#F5F4EC]">
      <div className="flex flex-col justify-center items-center w-full h-full md:h-screen">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="pb-8 pl-4">
            <h1 className="text-4xl font-bold inline border-b-4 border-[#804E49] text-[#DCD8BC]">
              About
            </h1>
          </div>
          <div></div>
        </div>
        <div
          className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-0 px-10 pr-30"
        >
          <div className="justify-center items-center">
            <img className="w-[330px] py-6" src={Rory} alt="/" />
          </div>
          <div className="justify-center items-center">
            <h1 className="text-4xl font-bold py-6 text-[#DCD8BC] ">
              Making the leap...{" "}
            </h1>
            <p className="pb-6">
              In 2022, I took the decision to leave behind my career as an Account Manager to
              enrol on the School of Code Intensive Coding Bootcamp
              and pursue my ambition to work in tech.
              <br />
              <br />I am now looking for junior developer roles!
            </p>
            <div className="pb-10">
              <Link to="contact" smooth={true} duration={500}>
                <button className="text-[#DCD8BC] group border-2 border-[#DCD8BC] px-6 py-3 my-2 flex items-center hover:bg-[#804E49] hover:border-[#804E49]">
                  Let's Talk
                  <span className="group-hover:rotate-90 duration-300">
                    <HiArrowNarrowRight className="ml-3 " />
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
