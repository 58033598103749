import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#5F7367] text-[#DCD8BC] text-xl">
      <div>
        <Link to="home" smooth={true} duration={500}>
          <h2 className="text-[#DCD8BC] text-2xl border-2 border-[#DCD8BC] px-6 py-3 my-2 flex items-center hover:bg-[#804E49] hover:border-[#804E49]">
            {" "}
            r o r y h o g a n{" "}
          </h2>
        </Link>
      </div>

      {/* menu */}
      <ul className="hidden md:flex">
        <li className="hover:text-[#F5F4EC] hover:scale-110 duration-500">
          <Link to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
          <li className="hover:text-[#F5F4EC] hover:scale-110 duration-500">
          <Link to="projects" smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li className="hover:text-[#F5F4EC] hover:scale-110 duration-500">
          <Link to="tech" smooth={true} duration={500}>
            Tech
          </Link>
        </li>
        <li className="hover:text-[#F5F4EC] hover:scale-110 duration-500">
          <Link to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#5F7367] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="tech" smooth={true} duration={500}>
            Tech
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="projects"
            smooth={true}
            duration={500}
          >
            Projects
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
